import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import {
	Container,
	Grid,
	Typography,
	Button,
	useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/General/Hero';
import { HeroImageRight } from '../components/General/HeroImageRight';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Resources } from '../components/General/Resources';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { HeroVideo } from '../components/General/HeroVideo';
import LitePageContext from '../context/LitePageContext';
const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	heroVideoCont: {
		marginBottom: '4rem',
		[theme.breakpoints.down('md')]: {
			marginBottom: '2rem',
		},
	},
	formCont: {
		padding: '12rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '8rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '5rem 0',
		},
	},
	faqTitle: {
		color: theme.workwaveBlue,
	},
}));

const Lite = ({ data, location }) => {
	const product = data.lite.edges[0].node;
	const classes = useStyles();

	const lg = useMediaQuery('(max-width: 1280px)');
	const theme = useTheme();

	const { setIsLite } = useContext(LitePageContext);

	useEffect(() => {
		setIsLite(true);

		return () => {
			setIsLite(false);
		};
	}, []);

	const {
		metaDescription,
		metaTitle,
		contactForm,
		formImages,
		formBgImage,
		pardotUrl,
		resourceTitle,
		resources,
		_rawResourceBody,
		heroAlignCenter,
		heroVideo,
		hero,
		heroImage,
		logoLeftCta,
	} = product;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			{heroAlignCenter && heroVideo ? (
				<div className={classes.heroVideo}>
					<Hero hero={hero} feature fs />
					<Container>
						<HeroVideo
							wistiaLink={heroVideo}
							button={hero.ctaButtonText || hero.ctaButtonLink}
						/>
					</Container>
				</div>
			) : heroAlignCenter ? (
				<Hero hero={hero} feature />
			) : (
				<HeroImageRight
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					feature
					lite
				/>
			)}
			<ProductFeatureBody body={product} logoLeftCta={logoLeftCta} lite />

			{/* <div>
				<WaveDownSVG height='213' width='100%' fill='white' />
			</div> */}

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					formImages={formImages}
					location={location}
				/>
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='white' /> */}
			<Container>
				<Resources
					header={resourceTitle}
					subheader={_rawResourceBody}
					resources={resources}
					noWave
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query LitePageQuery {
		lite: allSanityLite {
			edges {
				node {
					title
					metaTitle
					metaDescription
					slug {
						current
					}
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 100)
							}
						}
						mobileBackgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaButtonText
						ctaButtonLink
						internalLink
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					heroVideo
					heroAlignCenter
					_rawOverviewContent
					overviewImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					featureHeader
					featureSection {
						_key
						title
						cardTitle
						cardFaIcon
						cardIconColor {
							hexValue
						}
						bodySubHeader
						bodyHeader
						image {
							featureImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						videoVariant
						androidLink
						iosLink
						qbLink
						# svgVariant
						thumbnailVideoUrl
						ctaText
						ctaLink
						_rawBodyCopy
						internalLink
					}
					# cta {
					# 	ctaHeader
					# 	ctaSubheader
					# 	ctaLink
					# 	ctaText
					# 	internalLink
					# 	ctaBackground {
					# 		asset {
					# 			gatsbyImageData(placeholder: BLURRED)
					# 		}
					# 	}
					# }
          logoLeftCta {
						# _rawCtaBlockHeader
						_rawCtaHeader
						ctaSubheader
						ctaText
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaLink
						internalLink
						ctaLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					testimonialTitle
					testimonial {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						company
						testimonialText
						nameAndTitle
						videoVariant
						image {
							asset {
								gatsbyImageData(
									fit: FILLMAX
									height: 450
									width: 775
									placeholder: BLURRED
								)
							}
						}
					}
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					pardotUrl
					customerLogosHeader
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	}
`;

export default Lite;
